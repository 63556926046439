<template>
    <div class="about pa-10">
        <v-row class="flex-row justify-start">
            <v-col class="col-md-12 pa-3">
                <v-card class="ma-3">
                    <v-card-title>Category Information</v-card-title>
                    <v-divider></v-divider>

                    <v-container fluid>
                        <v-form
                            v-model="valid"
                            @submit.prevent="submitCategoryForm"
                            ref="form"
                        >
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        class="ml-3"
                                        label="Category Name"
                                        :rules="rules.name"
                                        v-model="form.name"
                                        outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-select
                                        class="ml-3"
                                        :items="parentCategoryData"
                                        item-text="name"
                                        item-value="id"
                                        label="Parent Category"
                                        v-model="form.category_id"
                                        outlined
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        class="ml-3"
                                        label="Order Level"
                                        :rules="rules.priority"
                                        v-model="form.priority"
                                        outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-file-input
                                        outlined
                                        label="Banner"
                                        :rules="rules.banner"
                                        v-model="files.banner"
                                    ></v-file-input>
                                    <a
                                        target="_blank"
                                        v-if="categoryDetail.banner"
                                        :href="categoryDetail.banner_file.url"
                                        class="ml-10"
                                    >
                                        View Banner
                                    </a>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        class="ml-3"
                                        label="Meta Title"
                                        :rules="rules.meta_title"
                                        v-model="form.meta_title"
                                        outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        class="ml-3"
                                        label="Meta Description"
                                        :rules="rules.meta_description"
                                        v-model="form.meta_description"
                                        outlined
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        class="ml-3"
                                        label="Slug"
                                        :rules="rules.slug"
                                        v-model="form.slug"
                                        outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-file-input
                                        outlined
                                        label="Meta Image"
                                        :rules="rules.meta_image"
                                        v-model="files.meta_image"
                                    ></v-file-input>
                                    <a
                                        target="_blank"
                                        v-if="categoryDetail.meta_image"
                                        :href="categoryDetail.meta_image_file.url"
                                        class="ml-10"
                                    >
                                        View Banner
                                    </a>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="text-right">
                                    <v-btn
                                        color="primary"
                                        type="submit"
                                        :loading="loading"
                                        :disabled="loading"
                                    >Submit
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Category from "@/apis/Category";

export default {
    name: "AddCategoryView",

    mounted() {
        this.$parent.$parent.$parent.selectedItem = 2;
        this.form.store_id = this.$store.state.store_id;
        this.getCategoryData();

        if (this.$route.params.id) {
            this.form.id = this.$route.params.id;
            this.getCategoryDetail();
        }
    },

    data: () => ({
        valid: false,
        mobile: "",
        rules: {
            name: [(v) => !!v || "Category Name is required"],
            priority: [(v) => !!v || "Priority is required"],
            meta_title: [(v) => !!v || "Meta Title is required"],
            meta_description: [(v) => !!v || "Meta Description is required"],
            slug: [(v) => !!v || "Slug is required"],
            banner: [
                (value) =>
                    !value ||
                    value.size < 2000000 ||
                    "Banner size should be less than 2 MB!",
            ],
            meta_image: [
                (value) =>
                    !value ||
                    value.size < 2000000 ||
                    "Meta Image size should be less than 2 MB!",
            ],
        },
        loading: false,
        parentCategoryData: [],
        storeOption: [],
        form: {
            name: "",
            category_id: "",
            priority: "",
            meta_title: "",
            meta_description: "",
            slug: "",
            id: "",
            store_id: "",
        },
        files: {
            meta_image: null,
            banner: null,
        },
        categoryDetail: [],
    }),

    methods: {
        getCategoryData: function () {
            Category.index(this.form)
                .then((response) => {
                    this.parentCategoryData = response.data.data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        getCategoryDetail: function () {
            Category.show(this.form.id)
                .then((response) => {
                    this.categoryDetail = response.data.data;

                    this.form.name = this.categoryDetail.name;
                    this.form.category_id = this.categoryDetail.category_id;
                    this.form.priority = this.categoryDetail.priority;
                    this.form.meta_title = this.categoryDetail.meta_title;
                    this.form.meta_description = this.categoryDetail.meta_description;
                    this.form.slug = this.categoryDetail.slug;
                    this.form.store_id = this.categoryDetail.store_id;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        submitCategoryForm: function () {
            if (this.$refs.form.validate()) {
                this.loading = true;

                const fd = new FormData();

                for (var element in this.form) {
                    fd.append(element, this.form[element]);
                }

                for (var fileElement in this.files) {
                    fd.append(fileElement, this.files[fileElement]);
                }

                if (this.form.id) {
                    fd.append("_method", "PATCH");
                }

                Category.store(fd, this.form.id)
                    .then(() => {
                        this.loading = false;
                        this.$refs.form.reset();

                        this.$notify({
                            title: "Success",
                            text: "Category Information Updated !!",
                            type: "success",
                            duration: 10000,
                        });

                        this.$router.push({ name: "category" });
                    })
                    .catch((error) => {
                        this.loading = false;

                        var text = error.response.data.message;
                        if (error.response.data.errors) {
                            for (var value in error.response.data.errors) {
                                if (text) text += "<br>";
                                text += error.response.data.errors[value];
                            }
                        }

                        this.$notify({
                            title: "Error",
                            text: text,
                            type: "error",
                            duration: 10000,
                        });
                    });
            }
        },
    },

    watch: {
        "form.category_id"(val) {
            this.getCategoryData(val);
        },
        "form.store_id"() {
            this.getCategoryData();
        },
    },
};
</script>
